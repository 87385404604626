/**
 * Delete an individual cart item
 * @param {Number} itemId 
 * @param {string} cartId
 * @param {string} product_plan_id
 * @returns Cart Response
 */

import axios from "axios"

export default async function RemoveCartItem(itemId, cartId, product_plan_id = null){
    const removeCartItem = axios.delete(`/api/bigcommerce/cart/${cartId}?itemId=${itemId}`)
    const removeProtectionPlan = product_plan_id ? axios.delete(`/api/bigcommerce/cart/${cartId}?itemId=${product_plan_id}`) : null

    return await Promise.all([removeProtectionPlan, removeCartItem])
        .then(data => data?.data)
        .catch(err => err)
}
