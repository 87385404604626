import { removeRejoinerCartItem } from "thirdparty/Rejoiner/Rejoiner.service";


/**
 * Sort and add up cart totals
 * @param {object} cartData 
 * @returns {object} { total, subTotal, planTotal, cartItems }
 */

export function processCartItems(cartData){
    let total = 0;
    let subTotal = 0;
    let planTotal = 0;
    const cartItems = [];

    if( cartData?.line_items?.physical_items ){
        const physicalItems = [...cartData?.line_items?.physical_items ?? cartData?.cart?.line_items?.physical_items];
        const customItems = [...cartData?.line_items?.custom_items ?? cartData?.cart?.line_items?.custom_items];

        physicalItems.forEach(item => { 
            total = (item.quantity * item.list_price) + total;
            subTotal = (item.quantity * item.list_price) + subTotal;

            cartItems.push(item)
        });

        if( customItems ){                        
            customItems.forEach(item => {
                total = (item.quantity * item.list_price) + total;
                planTotal = (item.quantity * item.list_price) + planTotal;

                if( item.sku.includes(";xtd;LEAD::") ){
                    cartItems.push(item)
                }
            })
        }
    }

    return { 
        total, 
        subTotal, 
        planTotal,
        cartItems 
    }
}




/**
 * Luhn Credit Card Initial Verification
 * @param {Number} num 
 * @returns CC Validation Pass/Fail Boolean
 */

export function luhnCheck(num){
    const arr = (num + '').split('').reverse().map(x => parseInt(x));
    const lastDigit = arr.splice(0, 1)[0];
    let sum = arr.reduce((acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val * 2) % 9) || 9), 0);

    sum += lastDigit;

    return sum % 10 === 0;
}







/**
 * cart the quantity of products in a cart
 * @param {object} cart 
 * @returns {number} count
 */

export function getCartQnty(cart){
    if( !cart?.line_items ){ return 0; }

    const products = [].concat(cart?.line_items?.physical_items, cart?.line_items?.custom_items)
    const productQuantities = products.map(item => item?.quantity ?? 0)

    const count = productQuantities.reduce((prev, curr) => prev + curr);

    return count;
}





/**
 * remove this item
 * @param {string} id
 * @param {string} cartId
 * @param {object} itemMutation
 * @param {number} product_id 
 * @param {object} product_plan_id - protection plan id
 */

export function removeCartItem({
    id, 
    cartId, 
    itemMutation, 
    product_id = null,
    product_plan_id = null,
    callback = () => null
}){

    if( product_id ){
        removeRejoinerCartItem(product_id)
    }

    itemMutation.mutate({ 
        productId: id, 
        cartId, 
        type: "remove", 
        id: product_id,
        product_plan_id
    }, {
        onSettled: () => callback(),
        onSuccess: async data => {
            if( data?.response?.status === 404 && product_id ){
                const reactToast = await import("react-toastify");
                reactToast.toast.error(`Error removing this product from cart`)   

            }else{
                callback(data)
            }
        }
    })
}