import whilst  from "async/whilst";
import * as Sentry from '@sentry/nextjs';


/**
 * Batch Process adding persisted cart items
 * @param {object} persistedCart - persistedCart object 
 * @param {object} itemMutation - mutation hook
 * @param {function} onComplete - completion callback
 */

export default function atcPersistedCart({
    persistedCart,
    customerId = 0,
    itemMutation,
    onComplete = () => null
}){
    let index = 0;
    let errors = [];
    const cartItems = Object.values(persistedCart?.items)

    whilst(
        (cb) => { cb(null, index < cartItems.length); },
        (callback) => {
            
            const productBody = {
                ...cartItems[index],
                customerId,
                line_items: []
            };

            productBody.line_items.push({
                ...cartItems[index].line_items,
            })
            

            itemMutation.mutate({ type: "add", productBody }, 
                {
                    onSuccess: (data) => {
                        if( 
                            data?.response?.status === 404 ||
                            data?.response?.status === 308
                        ){
                            Sentry.withScope(scope => {
                                scope.setTag("cart", "error");
                                scope.setLevel("error");
                                Sentry.captureException(data.response);
                            });

                            errors.push(true)
                        }

                        callback(null, index);

                        onComplete(data);
                    },

                    onError: async (err) => {
                        Sentry.withScope(scope => {
                            scope.setTag("cart", "error");
                            scope.setLevel("error");
                            Sentry.captureException(new Error(`Error refreshing cart`, ...err ));
                        });
                    }
                }
            )

            index++;

        }, (err) => {
            if( errors.length || err ){
                console.log(errors)
                console.log(err)
            }

            onComplete();
        }
    );

}