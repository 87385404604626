/**
 * Remove promo code from cart
 * @param {String} couponCode 
 * @param {string} cartId
 * @returns UpdateCart
 */

import axios from "axios"

export default async function DeleteCoupon(couponCode, cartId){
    return await axios.delete(`/api/bigcommerce/checkout/coupons/${cartId}?couponCode=${couponCode}`)
        .then(response => {
            return {
                data: response?.data?.data?.cart
            }
        })
        .catch(err => err)
}

//  https://api.bigcommerce.com/stores/{$$.env.store_hash}/v3/catalog/products?include_fields=categories,brand_id,sku,name,custom_url&categories:in=2480
