import CreateCartAddItem from "./CreateCartAddItem"
import RemoveCartItem from "./RemoveCartItem"

/**
 * Updates the last (or if 1 item) item in the cart
 * we have to first delete the item, then create a new cart with that 
 * updated item
 * @param {object} body 
 * @param {number} itemId 
 * @param {string} cartId 
 * @returns 
 */

export default async function UpdateLastCartItem (body, itemId, cartId){
    const removeItem = RemoveCartItem(itemId, cartId);
    const createItem = CreateCartAddItem(body);
    
    return await Promise.all([removeItem, createItem])
        .then(data => data[1].data)
        .catch(err => err)
}
