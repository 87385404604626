"use client"

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AddToCart } from 'components/applications/cart/contexts/CartContext';
import GetCart from 'services/bigcommerce/GetCart';
import UpdateLastCartItem from 'services/bigcommerce/UpdateLastCartItem';
import UpdateCartItem from 'services/bigcommerce/UpdateCartItem';
// import UpdateCartItemQty from 'services/bigcommerce/UpdateCartItemQty';
import AddCoupon from 'services/bigcommerce/AddCoupon';
import DeleteCoupon from 'services/bigcommerce/DeleteCoupon';
import UpdateCustomer from 'services/bigcommerce/UpdateCustomer';
import RemoveCartItem from 'services/bigcommerce/RemoveCartItem';
import AddTradeUserMessage from 'services/bigcommerce/AddTradeUserMessage';
import GetCartById from 'services/bigcommerce/GetCartById';
import { useRouter } from 'next/navigation';
import PersistedCart from 'components/applications/cart/helpers/persistedCart';
import { useEffect } from 'react';
import CreateCartAddItem from 'services/bigcommerce/CreateCartAddItem';
import DeleteCart from 'services/bigcommerce/DeleteCart';
import { LocalStorage, storageKeys } from 'services/LocalStorage.service';
import PutCartItem from 'services/bigcommerce/PutCartItem';


/**
 * Global Add to Cart Hook
 * @returns mutation method, cart, and cart event state
 */

export function useCart(){
    const queryClient = useQueryClient()
    const router = useRouter()
    const persistedCart = new PersistedCart()

    const itemMutation = useMutation(({ 
        type, 
        id, 
        productBody, 
        cartId, 
        productId, 
        code, 
        customerId, 
        buyNow, 
        product_plan_id = null,
        refresh = false 
    }) => {
            switch(type){
                case "getCartById":
                    return GetCartById(id); 

                case "create":
                    return CreateCartAddItem(productBody, buyNow); 

                case "delete":
                    return DeleteCart(cartId); 

                case "add": 
                    return AddToCart(productBody, buyNow, cartId); 

                case "updateQty":
                    return PutCartItem({ id, body: productBody, cartId }); 

                case "addCoupon":
                    return AddCoupon({ coupon_code: code }, cartId); 

                case "removeCoupon":
                    return  DeleteCoupon(code, cartId); 

                case "updateItem":
                    return UpdateCartItem(productBody, id, cartId); 

                case "updateLastItem":
                    return UpdateLastCartItem(productBody, id, cartId); 

                case "updateCustomer":
                    return UpdateCustomer(cartId, { customer_id: parseInt(customerId) }); 

                case "addTradeUserMessage":
                    return AddTradeUserMessage(cartId); 

                case "removeTradeUserMessage":
                    return AddTradeUserMessage(cartId, message = ""); 

                case "remove": 
                case "removePlan": 
                    return RemoveCartItem(productId, cartId, product_plan_id); 

                default: return null;
            }

        },{
            mutationKey: "cart",
            onSuccess: ( response, variables ) => {
                /* 
                    preventing full cart mutation because BigCommerce 
                    doesn't bring back options for the coupon response
               
                    Also, we need to merge the cart back in to the object for these 
                    methods as BC changes the shape of the cart response 
                */

                let responseData = null;

                switch(variables.type){
                    case "addCoupon":
                    case "removeCoupon":
                    case "addTradeUserMessage":
                    case "removeTradeUserMessage":
                        responseData = variables?.prevCart && {
                            ...variables?.prevCart,
                            ...response?.data,
                            line_items: {
                                ...variables?.prevCart?.line_items,
                            }
                        };

                    default: responseData = response?.data;
                }


                if( 
                    responseData || 
                    (response?.id && Array.isArray(response?.line_items?.physical_items) && response?.line_items?.physical_items.length) 
                ){
                    const cartResponse = responseData ?? response;

                    persistedCart.set(variables, cartResponse)                    
                    queryClient.setQueryData(['cart'], cartResponse)

                    if( variables.type === "removeCoupon" || 
                        variables.type === "addCoupon" ||
                        variables.type === "remove" ||
                        variables.type === "updateQty" ||
                        variables.type === "updateLastItem" ||
                        variables.type === "updateItem"
                    ){
                        router.replace(`/cart?id=${cartResponse?.id}`, { scroll: false })
                    }

                }else if( (response === "") && variables.type !== "removePlan" ){
                    persistedCart.remove()
                    queryClient.setQueryData(['cart'], null)

                    router.push(`/cart`)
                }
            }
        }
    )


    useEffect(() => {        
        if( persistedCart.isCartStale && !!persistedCart.cart ){
            LocalStorage.remove(storageKeys.cart)
            persistedCart.refreshCart(itemMutation)
        }

    }, [
        persistedCart.isCartStale
    ])




    const { data: cartData, isLoading, isError, isSuccess } = useQuery({
        queryKey: ["cart"],
        queryFn: () => GetCart(),
    })


    return{
        itemMutation,
        cartData,
        isLoading,
        isError,
        isSuccess
    }
}