/**
 * Update a given product in cart 
 * as of Aug 2021, via BC API running a PUT for this same operation doesn't work for products
 * that have product options 
 * 
 * @param {Object} body 
 * @param {string} cartId
 * @param {Number} itemId 
 * @returns the added item to Cart
 */

import axios from "axios"


export default async function UpdateCartItem(body, itemId, cartId){
     const responseData = await axios.delete(`/api/bigcommerce/cart/${cartId}?itemId=${itemId}`)
        .then((response) => {
            return setTimeout(async () => {
                return await axios.post(`/api/bigcommerce/cart/${cartId}`, body)
                    .then((response) => response)
                    .catch((error) => error)

            }, 1000)
        })
        .catch((error) => error)


    return responseData;


}
